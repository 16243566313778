export const API_URL = 'https://api-dev.r-ound.me';
export const MEDIA_URL = `${API_URL}/media`;
export const APP_NAME = 'dev.R‑ound.Me';
export const APP_URL = 'https://dev.r-ound.me';
export const CONTACT_EMAIL = 'contact‑dev@r‑ound.me';
export const I18N_DEBUG = true;
export const LAUNCH_DATE = new Date('2023-02-24T00:00:00');

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoicm91bmRtZSIsImEiOiJjbGduaXI1dWQwYnZtM3Bxa2piaTVoaDdoIn0.FKrO7e2ZD6mFOyayQ-tnjg';

export const VAPID_KEY = 'BMDijnLmmMcKJz1EW_chLG2jxZbULAr0cjByP0uLCTHJB1VsV43mqRLo7yn0BnFeFpqxw5e-uCcIzVIUCBPKPMw';
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyA8f2wDTCcdiBn3kMCCnEJ-y7ikgtevR-k',
  authDomain: 'dev-r-ound-me.firebaseapp.com',
  projectId: 'dev-r-ound-me',
  storageBucket: 'dev-r-ound-me.appspot.com',
  messagingSenderId: '917572477638',
  appId: '1:917572477638:web:447fe8143ded72d0ee6dc7',
  measurementId: 'G-T3CXJKYTXB'
};

export const PREMIUM_DISABLED = false;